<template>
<div>
    <v-row justify="center">
        <v-col cols="12">
            <p class="subheading font-weight-regular">
         {{$t('confirm_amt')}}
        </p>
         <small class="font-weight-regular">
        {{$t('click_outside')}}
        </small>
        <div>
         <small class="font-weight-regular">
       {{$t('reenter_card1')}}<strong>{{$t('reenter_card_bold')}}</strong>{{$t('reenter_card2')}}
        </small>
        </div>
        </v-col>
        <v-col cols="12">
          <v-row align="center" justify="center">
            <p class="display-2 font-weight-regular">
        $ {{($store.getters.getTotal/100).toFixed(2)}}
        </p>
        <small class="ml-2">CAD</small>
        </v-row>
        </v-col>
        <v-col lg="6" cols="12">
           <v-stripe-card
           outlined
    v-model="token"
    :api-key="pbKey"
  ></v-stripe-card>
        </v-col>
    </v-row>
    <!-- Loader Box section start -->
    <v-dialog v-model="loader" hide-overlay persistent width="300">
      <v-card color="secondary">
        <v-card-text class="text-center primary--text">
          {{$t('processing')}}
          <v-progress-linear rounded indeterminate color="primary" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Loader Box section end -->
    </div>
</template>
<script>
import { fstore } from '@/plugins/firebase'
export default {
  data: () => ({
    token: null,
    pbKey: process.env.VUE_APP_STRIPE_API_KEY,
    cardInfo: '',
    loader: false
    // stripeResponse: '',
    // errMsg: ''
  }),
  watch: {
    token: function (val) {
      // console.log(val)
      this.cardInfo = val
      this.$store.dispatch('enableConfirmAction')
      // this.$store.dispatch('stripeResponseAction', val)
    }
    // !! for testing loading UI !!
    // loader (val) {
    //   if (!val) return
    //   setTimeout(() => {
    //     this.loader = false
    //     this.$emit('last-step')
    //   }, 3000)
    // }
  },
  computed: {
    localDate () {
      var date = new Date().toLocaleDateString()
      return date
    },
    localTime () {
      var time = new Date().toLocaleTimeString()
      return time
    }
    // total () {
    //   var tot = Number(this.$store.getters.getTotal) * 100
    //   return tot
    // }
  },
  methods: {
    saveTransaction () {
      // construct charge object
      const charge = {}
      charge.id = this.cardInfo.id
      charge.clientIp = this.cardInfo.client_ip
      charge.donationAmt_entered = (this.$store.getters.getTotal / 100).toFixed(2)
      charge.totalDonation = this.$store.getters.getTotal
      charge.description = 'Donation for Cabaret Flamenco de Montreal'
      charge.payed_on = this.localDate
      charge.payed_at = this.localTime
      this.loader = true
      // console.log('Constructed charge is :', charge)
      // save the transaction info to database
      const cabaretRef = fstore.collection('cabaretFlamenco_Montreal_transactions').doc()
      const id = cabaretRef.id
      fstore
        .collection('cabaretFlamenco_Montreal_transactions')
        .doc(id)
        .set(charge)
        .then(() => {
          this.$store.dispatch('disableConfirmAction')
          this.$store.dispatch('disableCancelAction')
          fstore
            .collection('cabaretFlamenco_Montreal_transactions')
            .where('id', '==', this.cardInfo.id)
            .onSnapshot((querySnap) => {
              var response = [] // <-- reset the array here
              querySnap.docChanges().forEach(change => {
                if (change.type === 'modified') {
                  this.loader = false
                  response.push(change.doc.data())
                  // emit an event when a response is registered
                  this.$emit('last-step')
                }
              })
              // set response in the store
              this.$store.dispatch('stripeDonationResponseAction', response)
              this.$store.dispatch('bindDonationTransactionCollection', id)
            })
        }).catch(err => {
          // this.errMsg = err
          this.$store.dispatch('errMsgAction', err)
        })
    }
  }
}
</script>
<i18n>
{
  "en":{
    "confirm_amt":"Please enter your payment card details",
    "click_outside":"click outside the credit card box after entering payment info for confirming.",
    "reenter_card1":"To make another donation, ",
    "reenter_card_bold":"re-enter ",
    "reenter_card2":"your credit card number and click outside the box to confirm.",
    "processing":"Processing payment"
  },
  "fr":{
    "confirm_amt":"Veuillez entrer vos informations de carte de crédit",
    "click_outside":"pour confirmer, cliquez en dehors de la boîte de carte de crédit.",
    "reenter_card1":"Pour faire un autre don, ",
    "reenter_card_bold":"entrez à nouveau ",
    "reenter_card2":"votre numéro de carte de crédit et cliquez en dehors de la boîte pour confirmer.",
    "processing":"Paiement en cours"
  },
  "es":{
    "confirm_amt":"Introduzca los datos de su tarjeta de crédito",
    "click_outside":"para confirmar, haga clic fuera de la casilla de la tarjeta de crédito",
    "reenter_card1":"Si está haciendo una segunda donación, ",
    "reenter_card_bold":"introduzca de nuevo ",
    "reenter_card2":"su número de tarjeta de crédito y haga clic fuera de la casilla para confirmar.",
    "processing":"Procesando pago"
  }
}
</i18n>
